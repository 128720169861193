/* eslint-disable prettier/prettier */
/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const token =
    typeof window !== "undefined" ? localStorage.getItem("status") : null;
  const [unseenNotification, setUnseenNotification] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchNotificationData = async () => {
    try {
      const res = await axios.get("", {
        headers: { Authorization: "Bearer " + token },
      });
      setUnseenNotification(res.data.data);
      setTotalRecords(res.data.notification_count);
      console.log("API Response:", res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      <DataContext.Provider
        value={{ unseenNotification, totalRecords, fetchNotificationData }}
      >
        {children}
      </DataContext.Provider>
    </>
  );
};

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useDataContext = () => {
  return useContext(DataContext);
};
